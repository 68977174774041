import { forwardRef } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import { Alert, AlertProps, AlertTitle, Box } from '@mui/material'

import { AcTypography } from '@components'

type Omitted = 'variant'

export interface IAcAlert extends Omit<AlertProps, Omitted> {
  title?: string
  variant?: 'filled' | 'standard'
  onClose?: () => void
}

// Alert needs a forwardRef for the AcToaster animation
// See issue: https://github.com/mui/material-ui/issues/21089
export const AcAlert = forwardRef(
  (
    {
      severity,
      title,
      children,
      variant = 'filled',
      onClose,
      ...props
    }: IAcAlert,
    ref,
  ) => {
    return (
      <Box ref={ref}>
        <Alert
          severity={severity}
          variant={variant}
          onClose={onClose}
          iconMapping={{
            success: <CheckCircleIcon fontSize="small" />,
            warning: <ErrorIcon fontSize="small" />,
            error: <WarningIcon fontSize="small" />,
            info: <InfoIcon fontSize="small" />,
          }}
          {...props}>
          {title && <AlertTitle>{title}</AlertTitle>}
          <AcTypography
            component="span"
            size="sm"
            style={{ whiteSpace: 'pre-line' }}>
            {children}
          </AcTypography>
        </Alert>
      </Box>
    )
  },
)

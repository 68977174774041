import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import {
  AcCheckbox,
  AcCheckboxGroup,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcGridContainer,
  AcGridItem,
  AcLoaderPulse,
  AcOrderFooter,
  AcRow,
  AcTextarea,
  AcTypography,
} from '@components'
import { CURRENT_ORDER_KEYS, LABELS } from '@constants'
import { useBreakpoints, useStore } from '@hooks'
import {
  IOrderDetailsOverviewSoupOrSalad,
  IOrderPreferencePayload,
  ISoupSaladMenuProduct,
} from '@typings'

const FormInputs = ({
  loading,
  weekIdentifier,
}: {
  loading: boolean
  weekIdentifier: string
}) => {
  const { currentOrder, toaster } = useStore()
  const { isMobile } = useBreakpoints()
  const { watch, setValue } = useFormContext()
  const watchNoComment = watch(CURRENT_ORDER_KEYS.NO_COMMENT)
  const watchSalad = watch('salad')
  const watchSoup = watch('soup')

  const checkSalads = () => {
    const missingSalads: IOrderDetailsOverviewSoupOrSalad[] = []
    const availableSalads: ISoupSaladMenuProduct[] = []
    if (currentOrder?.formValues?.salad?.length) {
      currentOrder.watchAvailableSalads?.forEach(group => {
        group.products.forEach(prod => availableSalads.push(prod))
      })

      currentOrder?.formValues?.salad.forEach((saladId: number) => {
        const saladDetail = availableSalads?.find(salad => salad.id === saladId)
        if (!saladDetail) {
          const saladDetail = currentOrder.watchCurrentOrderSaladDetails?.find(
            item => item.id === saladId,
          )
          if (saladDetail?.id) {
            missingSalads.push(saladDetail)
          }
        }
      })
    }

    return {
      names: missingSalads.map(({ name }) => name),
      ids: missingSalads.map(({ id }) => id),
    }
  }

  const checkSoups = () => {
    const missingSoups: IOrderDetailsOverviewSoupOrSalad[] = []
    const availableSoups: ISoupSaladMenuProduct[] = []

    if (currentOrder?.formValues?.soup?.length) {
      currentOrder.watchAvailableSoups?.forEach(group => {
        group.products.forEach(prod => availableSoups.push(prod))
      })

      currentOrder.formValues.soup.forEach((soupId: number) => {
        const soupDetail = availableSoups?.find(soup => soup.id === soupId)
        if (!soupDetail) {
          const soupDetail = currentOrder.watchCurrentOrderSoupDetails?.find(
            item => item.id === soupId,
          )
          if (soupDetail?.id) {
            missingSoups.push(soupDetail)
          }
        }
      })
    }

    return {
      names: missingSoups.map(({ name }) => name),
      ids: missingSoups.map(({ id }) => id),
    }
  }

  const fetchMenus = async (id: string) => {
    await currentOrder.fetchAvailableMenus(id)

    const missingSalads = checkSalads()
    const missingSoups = checkSoups()

    if (missingSalads?.ids.length) {
      const newSaladValue = currentOrder?.formValues?.salad.filter(
        (value: number) => !missingSalads.ids.includes(value),
      )
      setValue(CURRENT_ORDER_KEYS.SALAD, newSaladValue)
    }

    if (missingSoups?.ids?.length) {
      const newSoupValue = currentOrder?.formValues?.soup.filter(
        (value: number) => !missingSoups?.ids.includes(value),
      )
      setValue(CURRENT_ORDER_KEYS.SOUP, newSoupValue)
    }

    if (missingSalads?.names?.length || missingSoups?.names?.length) {
      toaster.warning({
        title: 'Let op',
        content: `De volgende producten zijn niet meer beschikbaar: \n ${[...missingSalads?.names, ...missingSoups?.names].join(', ')}`,
      })
    }
  }

  useEffect(() => {
    fetchMenus(weekIdentifier)
  }, [weekIdentifier])

  useEffect(() => {
    if (watchNoComment) {
      setValue(CURRENT_ORDER_KEYS.COMMENT, '')
    }
  }, [watchNoComment])

  const renderSoups = useMemo(() => {
    if (loading) return <AcLoaderPulse />
    if (!currentOrder.watchAvailableSoups?.length) {
      return null
    }
    return (
      <AcColumn gap={1}>
        <AcTypography
          removePadding
          color="green.main"
          bold>
          {LABELS.WHAT_IS_YOUR_SOUP_PREFERENCE}
        </AcTypography>
        <AcTypography>{LABELS.AVAILABILITY_NOT_GUARANTEED}</AcTypography>
        <AcGridContainer>
          {currentOrder.watchAvailableSoups?.map((item, i) => (
            <AcGridItem
              key={i}
              mobile={12}
              tablet={6}>
              <AcColumn>
                <AcTypography removePadding>
                  {LABELS.AVAILABILITY}: {item.group}
                </AcTypography>
                <AcFormInput
                  register={CURRENT_ORDER_KEYS.SOUP}
                  Component={
                    <AcCheckboxGroup
                      fullWidth
                      useInt
                      id={`${CURRENT_ORDER_KEYS.SOUP}-${i}`}
                      options={item.products.map(prod => ({
                        id: prod.id,
                        value: prod.id,
                        title: prod.title,
                        label: prod.subtitle,
                      }))}
                    />
                  }
                />
              </AcColumn>
            </AcGridItem>
          ))}
        </AcGridContainer>
      </AcColumn>
    )
  }, [currentOrder.watchAvailableSoups, watchSoup, loading])

  const renderSalads = useMemo(() => {
    if (loading) return <AcLoaderPulse />
    if (!currentOrder.watchAvailableSalads?.length) {
      return null
    }
    return (
      <AcColumn gap={1}>
        <AcTypography
          removePadding
          color="green.main"
          bold>
          {LABELS.WHAT_IS_YOUR_SALAD_PREFERENCE}
        </AcTypography>
        <AcTypography>{LABELS.AVAILABILITY_NOT_GUARANTEED}</AcTypography>
        <AcGridContainer>
          {currentOrder.watchAvailableSalads?.map((item, i) => (
            <AcGridItem
              key={i}
              mobile={12}
              tablet={6}>
              <AcColumn>
                <AcTypography removePadding>
                  {LABELS.AVAILABILITY} {item.group}
                </AcTypography>
                <AcFormInput
                  register={CURRENT_ORDER_KEYS.SALAD}
                  Component={
                    <AcCheckboxGroup
                      fullWidth
                      useInt
                      id={`${CURRENT_ORDER_KEYS.SALAD}-${i}`}
                      options={item.products.map(prod => ({
                        id: prod.id,
                        value: prod.id,
                        title: prod.title,
                        label: prod.subtitle,
                      }))}
                    />
                  }
                />
              </AcColumn>
            </AcGridItem>
          ))}
        </AcGridContainer>
      </AcColumn>
    )
  }, [currentOrder.watchAvailableSalads, watchSalad, loading])

  const renderRemarks = useMemo(() => {
    return (
      <AcColumn gap={3}>
        <AcColumn gap={1}>
          <AcTypography
            removePadding
            color="green.main"
            bold>
            {LABELS.ANY_COMMENTS}
          </AcTypography>
          <AcTypography>{LABELS.ANY_COMMENTS_HELPER}</AcTypography>
          <AcFormInput
            register={CURRENT_ORDER_KEYS.COMMENT}
            Component={
              <AcTextarea
                label={LABELS.EXTRA_REMARK_LABEL}
                disabled={watchNoComment}
              />
            }
          />
        </AcColumn>
        <AcRow>
          <AcFormInput
            register={CURRENT_ORDER_KEYS.NO_COMMENT}
            Component={
              <AcCheckbox
                id={CURRENT_ORDER_KEYS.NO_COMMENT}
                variant="default"
                label={LABELS.ANY_COMMENTS_NONE}
              />
            }
          />
        </AcRow>
      </AcColumn>
    )
  }, [isMobile, watchNoComment])

  return (
    <AcColumn gap={6}>
      {renderSalads}
      {renderSoups}
      {renderRemarks}
    </AcColumn>
  )
}

export const AcOrderPreferencesForm = observer(
  ({
    weekIdentifier,
    orderId,
  }: {
    weekIdentifier: string
    orderId?: string
  }) => {
    const navigate = useNavigate()
    const { currentOrder, toaster } = useStore()
    const [errors, setErrors] = useState()

    const handleSubmit = async (body: IOrderPreferencePayload) => {
      const isOrderEdit = Boolean(currentOrder.currentOrderId)
      try {
        const res = await currentOrder.submitPreferences(body, isOrderEdit)
        if (res) {
          orderId
            ? navigate(NAVIGATE_TO.EDIT_ORDER_SUMMARY(weekIdentifier, orderId))
            : navigate(NAVIGATE_TO.ORDER_SUMMARY(weekIdentifier))
        }
      } catch (error: any) {
        if (error.type === 'axios') {
          setErrors(error.errorFields)
        }
        toaster.error({
          title: 'Nog niet alle velden zijn juist ingevuld',
          content: error.message,
        })
      }
    }

    return (
      <AcFormProvider
        initial={currentOrder.formValues}
        onSubmit={handleSubmit}
        errors={errors}>
        <FormInputs
          weekIdentifier={weekIdentifier}
          loading={currentOrder.watchLoadingStep}
        />
        <AcOrderFooter
          loading={currentOrder.watchLoadingSubmitting}
          previousStep={
            orderId
              ? NAVIGATE_TO.EDIT_ORDER(weekIdentifier, orderId)
              : NAVIGATE_TO.CREATE_ORDER(weekIdentifier)
          }
        />
      </AcFormProvider>
    )
  },
)

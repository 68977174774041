import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AcAnimatedFade } from '@animations'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import {
  AcColumn,
  AcContainer,
  AcOrderHeader,
  AcTable,
  AcTypography,
} from '@components'
import {
  CURRENT_ORDER_KEYS,
  LABELS,
  WEEKDAYS_KEYS,
  WEEKDAYS_LABELS,
} from '@constants'
import { useStore } from '@hooks'
import { AcOrderSummaryForm } from '@partials'
import { ISoupSaladMenuProduct } from '@typings'

export interface IOrderSummaryView {
  isEditOrder?: boolean
}

export const OrderSummaryView = observer(
  ({ isEditOrder }: IOrderSummaryView) => {
    const { weekIdentifier, orderId } = useParams()
    const { currentOrder, user, products } = useStore()
    const navigate = useNavigate()

    if (!weekIdentifier) {
      return null
    }

    const renderLuncherTable = useMemo(() => {
      if (user?.isBasicSubscription) return null
      const days = Object.entries(WEEKDAYS_KEYS).map(([key, value]) => ({
        key,
        value,
      }))
      return (
        <AcTable
          head={[LABELS.LUNCHER_COUNT, '']}
          rows={days.map(day => (
            <AcTable.Row>
              <AcTable.Cell>
                {WEEKDAYS_LABELS[day.key as keyof typeof WEEKDAYS_LABELS]}
              </AcTable.Cell>
              <AcTable.Cell align="right">
                {currentOrder.watchFormValues?.[day.value]}
              </AcTable.Cell>
            </AcTable.Row>
          ))}
        />
      )
    }, [currentOrder.watchFormValues])

    const renderRow = (title: string, description: string) => {
      return (
        <AcTable.Row>
          <AcTable.Cell>
            <AcColumn gap={1}>
              <AcTypography
                color="green.main"
                removePadding
                bold>
                {title}
              </AcTypography>
              <AcTypography
                color="green.main"
                removePadding>
                {description}
              </AcTypography>
            </AcColumn>
          </AcTable.Cell>
        </AcTable.Row>
      )
    }

    const renderSoupTable = useMemo(() => {
      if (!currentOrder?.watchFormValues) return null
      const soupList = currentOrder.watchFormValues[CURRENT_ORDER_KEYS.SOUP]
      if (!soupList?.length) return null

      const extractSoupsFromGroups = currentOrder.watchAvailableSoups
        ?.map(({ products }) => products)
        ?.flat()

      const fullSoupList = soupList
        .map((id: number) =>
          extractSoupsFromGroups?.find(soup => soup.id === id),
        )
        .filter(Boolean)

      if (!fullSoupList?.length) return null

      return (
        <AcTable
          head={[LABELS.SOUP_PREFERENCE]}
          rows={fullSoupList.map((soup: ISoupSaladMenuProduct) =>
            renderRow(soup?.title, soup?.subtitle),
          )}
        />
      )
    }, [currentOrder.watchAvailableSoups, currentOrder.watchFormValues])

    const renderSaladTable = useMemo(() => {
      if (!currentOrder?.watchFormValues) return null
      const list = currentOrder.watchFormValues[CURRENT_ORDER_KEYS.SALAD]
      if (!list?.length) return null

      const extractFromGroups = currentOrder.watchAvailableSalads
        ?.map(({ products }) => products)
        ?.flat()

      const fullList = list
        .map((id: number) => extractFromGroups?.find(salad => salad.id === id))
        .filter(Boolean)

      if (!fullList?.length) return null

      return (
        <AcTable
          head={[LABELS.SALAD_PREFERENCE]}
          rows={fullList.map((salad: ISoupSaladMenuProduct) =>
            renderRow(salad?.title, salad?.subtitle),
          )}
        />
      )
    }, [currentOrder.watchAvailableSalads, currentOrder.watchFormValues])

    const renderRemarks = useMemo(() => {
      if (user?.isBasicSubscription) return null
      return (
        <AcTable
          head={[LABELS.COMMENTS]}
          rows={[
            <AcTable.Row>
              <AcTable.Cell>
                {currentOrder.watchFormValues?.[CURRENT_ORDER_KEYS.COMMENT]
                  ? currentOrder.watchFormValues[CURRENT_ORDER_KEYS.COMMENT]
                  : LABELS.ANY_COMMENTS_NONE}
              </AcTable.Cell>
            </AcTable.Row>,
          ]}
        />
      )
    }, [currentOrder.watchFormValues])

    useEffect(() => {
      if (!currentOrder.watchFormValues && !user.isBasicSubscription) {
        orderId
          ? navigate(NAVIGATE_TO.EDIT_ORDER(weekIdentifier, orderId))
          : navigate(NAVIGATE_TO.CREATE_ORDER(weekIdentifier), {
              replace: true,
            })
      }
    }, [currentOrder.watchFormValues, user.isBasicSubscription])

    return (
      <AcAnimatedFade
        enter={{ x: 20, y: 0 }}
        exit={{ x: -20, y: 0 }}>
        <AcContainer>
          <AcColumn gap={6}>
            {!user?.isBasicSubscription && (
              <AcOrderHeader
                step={3}
                weekIdentifier={weekIdentifier}
                isEditOrder={isEditOrder}
                orderId={orderId}
              />
            )}
            <AcColumn gap={4}>
              <AcTypography
                component="h1"
                removePadding>
                Overzicht bestelling
              </AcTypography>
              <AcColumn>
                {renderLuncherTable}
                {renderSaladTable}
                {renderSoupTable}
                {renderRemarks}
              </AcColumn>
              <AcOrderSummaryForm isEditOrder={isEditOrder} />
            </AcColumn>
          </AcColumn>
        </AcContainer>
      </AcAnimatedFade>
    )
  },
)

import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AcAnimatedFade } from '@animations'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import { AcColumn, AcContainer, AcOrderHeader, AcTypography } from '@components'
import { useStore } from '@hooks'
import { AcOrderPreferencesForm } from '@partials'

export interface IOrderPreferencesView {
  isEditOrder?: boolean
}

export const OrderPreferencesView = observer(
  ({ isEditOrder = false }: IOrderPreferencesView) => {
    const { weekIdentifier, orderId } = useParams()
    const navigate = useNavigate()
    const { currentOrder, user } = useStore()

    if (!weekIdentifier) {
      return null
    }

    useEffect(() => {
      if (user.isBasicSubscription) {
        if (orderId) {
          navigate(NAVIGATE_TO.EDIT_ORDER_SUMMARY(weekIdentifier, orderId), {
            replace: true,
          })
        } else {
          navigate(NAVIGATE_TO.ORDER_SUMMARY(weekIdentifier), { replace: true })
        }
      } else if (!currentOrder.watchFormValues) {
        if (orderId) {
          navigate(NAVIGATE_TO.EDIT_ORDER(weekIdentifier, orderId), {
            replace: true,
          })
        } else {
          navigate(NAVIGATE_TO.CREATE_ORDER(weekIdentifier), { replace: true })
        }
      }
    }, [currentOrder.watchFormValues, user.isBasicSubscription])

    return (
      <AcAnimatedFade
        enter={{ x: 20, y: 0 }}
        exit={{ x: -20, y: 0 }}>
        <AcContainer>
          <AcColumn gap={6}>
            <AcOrderHeader
              step={2}
              weekIdentifier={weekIdentifier}
              isEditOrder={isEditOrder}
              orderId={orderId}
            />
            <AcColumn gap={4}>
              <AcTypography
                component="h1"
                removePadding>
                Waar heb je trek in?
              </AcTypography>
              <AcOrderPreferencesForm
                weekIdentifier={weekIdentifier}
                orderId={orderId}
              />
            </AcColumn>
          </AcColumn>
        </AcContainer>
      </AcAnimatedFade>
    )
  },
)

import { cloneElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import type { IAcFormInputRules } from '@typings'

import { AcBox } from '@components'
import { LABELS } from '@constants'

export interface IAcFormInputBase {
  register: string
  customError?: string
  rules?: IAcFormInputRules
  Component: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  lastChild?: boolean
}

export const AcFormInput = ({
  register,
  customError,
  Component,
  rules,
  lastChild,
}: IAcFormInputBase) => {
  const { control, watch } = useFormContext()

  return (
    <AcBox mb={lastChild ? 0 : 3}>
      <Controller
        name={register}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          let error =
            customError ||
            fieldState.error?.message ||
            (fieldState.error?.type === 'required' && LABELS.FIELD_REQUIRED)

          if (!error && Array.isArray(fieldState.error)) {
            error = fieldState.error.map(err => err?.message || '')?.join(' ')
          }

          return cloneElement(Component, {
            error,
            value: watch(register), // This trick is required as otherwise RHF might not re-render
            checked: field.value,
            onChange: field.onChange,
          })
        }}
      />
    </AcBox>
  )
}

import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { action, computed, makeAutoObservable } from 'mobx'

import { STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { fetchOrderByYearParams, IOrderDetails, IOrderSummary } from '@typings'

dayjs.extend(isoWeek)

export class OrdersStore {
  store: Store
  loading: boolean
  next_orders: IOrderSummary[] | undefined
  current_order: IOrderSummary | undefined
  previous_orders: IOrderSummary[] | undefined

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
    this.next_orders = []
    this.current_order = undefined
    this.previous_orders = []
  }
  @computed
  get watchLoading(): OrdersStore['loading'] {
    return this.loading
  }

  @computed
  get watchFutureOrders(): OrdersStore['next_orders'] {
    return this.next_orders
  }

  @computed
  get watchCurrentOrder(): OrdersStore['current_order'] {
    return this.current_order
  }

  @computed
  get watchPreviousOrders(): OrdersStore['previous_orders'] {
    return this.previous_orders
  }

  @action
  filterOrdersByYear = (year: number) => {
    return this.previous_orders?.filter(item => item.year === year)
  }

  @action
  fetchOrders = async () => {
    this.store.set(STORE_KEYS.ORDERS, 'loading', true)
    const currentWeek = dayjs().isoWeek()

    try {
      const res: IOrderSummary[] = await this.store.api.specifications.index()

      const nextOrders = res.filter(({ week }) => week >= currentWeek + 1)
      const currentOrder = res.find(({ week }) => week === currentWeek)
      const previousOrders = res.filter(({ week }) => week < currentWeek)

      this.store.set(STORE_KEYS.ORDERS, 'next_orders', nextOrders)
      this.store.set(STORE_KEYS.ORDERS, 'current_order', currentOrder)
      this.store.set(STORE_KEYS.ORDERS, 'previous_orders', previousOrders)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.store.set(STORE_KEYS.ORDERS, 'loading', false)
    }
  }

  @action
  fetchOrdersByYear = async (params: fetchOrderByYearParams) => {
    const currentWeek = dayjs().isoWeek()

    try {
      const { data, meta } =
        await this.store.api.specifications.index_year(params)
      const previousOrders = data.filter(
        ({ week, year }) =>
          week < currentWeek || year < new Date(Date.now()).getFullYear(),
      )

      return Promise.resolve({ data: previousOrders, meta })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  @action
  fetchSingleOrder = async (id: IOrderSummary['id']) => {
    this.store.set(STORE_KEYS.ORDERS, 'loading', true)

    try {
      const res: IOrderDetails =
        await this.store.api.specifications.get_single(id)
      this.store.set(STORE_KEYS.ORDERS, 'loading', false)

      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  @action
  reset = () => {
    this.loading = false
    this.next_orders = []
    this.current_order = undefined
    this.previous_orders = []
  }
}

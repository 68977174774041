import { IOrderDetails } from '@typings'

export const extractOrderSummaryExtrasFormValues = (
  orderResponseOverview: IOrderDetails['overview'],
) => {
  const extrasPerDay = orderResponseOverview?.extras?.days
  if (!extrasPerDay?.length) return null

  const extraFormValues: any = {}
  extrasPerDay.forEach(({ day, products }) => {
    products.forEach(product => {
      const key = `extras-${day}-${product.id}`
      const value = product.quantity

      extraFormValues[key] = value
    })
  })

  return extraFormValues
}

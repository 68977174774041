import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import BasketPink from '@assets/visuals/basket_pink.webp'
import TruckGreen from '@assets/visuals/truck_green.webp'
import {
  AcCard,
  AcGridContainer,
  AcGridItem,
  AcImage,
  AcRow,
  AcTypography,
} from '@components'
import { KEYS } from '@constants'
import { useDateHelpers } from '@hooks'
import { IOrderSummary } from '@typings'

export interface IAcOrderCard {
  order: IOrderSummary
  isNextWeekOrder?: boolean
}

export const AcOrderCard = ({
  isNextWeekOrder = false,
  order,
}: IAcOrderCard) => {
  const location = useLocation()
  const { weekStartDate, weekEndDateFriday } = useDateHelpers(
    order.start_of_week,
  )

  const renderTitle = useMemo(() => {
    return `Week ${order.week} - ${weekStartDate} t/m ${weekEndDateFriday}`
  }, [order])

  const renderContent = useMemo(() => {
    if (order?.status === KEYS.STATUS_CANCELLED)
      return `De bestelling voor week ${order.week} is geannuleerd.`
    return isNextWeekOrder
      ? 'We gaan aan de slag met het voorbereiden van jouw lokale en duurzame lunch.'
      : 'Je lunch wordt deze week bezorgd'
  }, [isNextWeekOrder, order.status])

  const renderSubtitle = useMemo(() => {
    if (order.status === KEYS.STATUS_CANCELLED) return null
    return isNextWeekOrder
      ? ' Bekijk wat je hebt besteld.'
      : 'Bekijk je weekmenu voor deze week en laat ons weten wat je ervan vindt.'
  }, [order.status])

  const renderHref = useMemo(() => {
    return `${location.pathname}/${order.id}`
  }, [order.status, order.id])

  return (
    <AcCard
      cardColor={isNextWeekOrder ? 'pink' : 'green'}
      href={renderHref}>
      <AcGridContainer>
        <AcGridItem
          mobile={12}
          tablet={6.5}>
          <AcTypography
            bold
            color={isNextWeekOrder ? 'orange.main' : 'pink.main'}>
            {renderTitle}
          </AcTypography>
          <AcTypography component="h3">{renderContent}</AcTypography>
          <AcTypography component="span">{renderSubtitle}</AcTypography>
        </AcGridItem>
        <AcGridItem
          mobile={12}
          tablet={5.5}>
          <AcRow justifyContent="flex-end">
            <AcImage
              src={isNextWeekOrder ? BasketPink : TruckGreen}
              sx={{ maxWidth: '100%' }}
            />
          </AcRow>
        </AcGridItem>
      </AcGridContainer>
    </AcCard>
  )
}

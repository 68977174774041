interface GroupByKey<T> {
  [key: string]: T[]
}

export function groupBy<T, K extends keyof T>(
  array: T[],
  key: K,
): GroupByKey<T> {
  return array.reduce((acc, item) => {
    const groupKey = String(item[key])

    if (!acc[groupKey]) {
      acc[groupKey] = []
    }

    acc[groupKey].push(item)
    return acc
  }, {} as GroupByKey<T>)
}

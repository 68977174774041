import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { AcAnimatedFade } from '@animations'
import { observer } from 'mobx-react-lite'

import {
  AcColumn,
  AcExtrasSwiper,
  AcLoaderPulse,
  AcTabs,
  AcTypography,
} from '@components'
import { LABELS, WEEKDAYS_KEYS, WEEKDAYS_LABELS } from '@constants'
import { useStore } from '@hooks'

export const AcOrdersummaryExtrasForminputs = observer(() => {
  const { weekIdentifier } = useParams()
  const { products, currentOrder, toaster } = useStore()
  const { unregister } = useFormContext()
  const keys = Object.entries(WEEKDAYS_KEYS).map(([key, value]) => ({
    key,
    value,
  }))

  if (!products?.current_extras) return <AcLoaderPulse />

  const renderTabs = useMemo(() => {
    if (!products?.current_extras?.length) return []
    return keys.map(day => ({
      title: WEEKDAYS_LABELS[day.key as keyof typeof WEEKDAYS_LABELS],
      content: (
        <AcAnimatedFade
          key={day.value}
          enter={{ x: -24, y: 0 }}
          exit={{ x: 24, y: 0 }}>
          <AcExtrasSwiper
            withInputs
            registerKey={`extras-${day.value}`}
            items={products.current_extras}
          />
        </AcAnimatedFade>
      ),
    }))
  }, [products?.current_extras])

  const checkExtrasAvailability = async () => {
    if (weekIdentifier) {
      await products.getExtras(weekIdentifier)

      const missingExtras: string[] = []
      Object.entries(currentOrder.formValues).forEach(([key]) => {
        if (key.includes('extras-')) {
          const productId = key.split('-')[2]
          const productStillAvailable = products.current_extras?.find(
            ({ id }) => id.toString() === productId,
          )
          if (!productStillAvailable) {
            const productDetails = currentOrder.findCurrentOrderExtraById(
              parseInt(productId),
            )
            if (productDetails?.name) {
              missingExtras.push(productDetails.name)
              unregister(key)
            }
          }
        }
      })
      if (missingExtras?.length) {
        toaster.warning({
          title: 'Let op',
          content: `De volgende extra's zijn niet meer beschikbaar: ${missingExtras.join(', ')}`,
        })
      }
    }
  }

  useEffect(() => {
    checkExtrasAvailability()
  }, [weekIdentifier])

  return (
    <AcColumn gap={1}>
      <AcTypography
        color="green.main"
        component="h3">
        {LABELS.EXTRAS}
      </AcTypography>
      <AcTabs
        gap={0}
        small
        tabs={renderTabs}
      />
    </AcColumn>
  )
})

import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AcAnimatedFade } from '@animations'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import { AcColumn, AcContainer, AcOrderHeader, AcTypography } from '@components'
import { useStore } from '@hooks'
import { AcOrderLunchersForm } from '@partials'

export interface IOrderLunchersView {
  isEditOrder?: boolean
}

export const OrderLunchersView = observer(
  ({ isEditOrder = false }: IOrderLunchersView) => {
    const { weekIdentifier, orderId } = useParams()
    const { user, currentOrder } = useStore()
    const navigate = useNavigate()

    if (!weekIdentifier) {
      return null
    }

    useEffect(() => {
      if (user.isBasicSubscription) {
        navigate(NAVIGATE_TO.ORDER_SUMMARY(weekIdentifier), { replace: true })
      }

      currentOrder.setWeekIdentifier(weekIdentifier)
    }, [])

    return (
      <AcAnimatedFade
        enter={{ x: 20, y: 0 }}
        exit={{ x: -20, y: 0 }}>
        <AcContainer>
          <AcColumn gap={6}>
            <AcOrderHeader
              step={1}
              weekIdentifier={weekIdentifier}
              isEditOrder={isEditOrder}
              orderId={orderId}
            />
            <AcColumn gap={4}>
              <AcTypography
                component="h1"
                removePadding>
                Hoeveel mensen willen er samen lunchen?
              </AcTypography>
              <AcOrderLunchersForm
                weekIdentifier={weekIdentifier}
                orderId={orderId}
              />
            </AcColumn>
          </AcColumn>
        </AcContainer>
      </AcAnimatedFade>
    )
  },
)

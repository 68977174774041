import { useNavigate } from 'react-router-dom'
import { AcAnimatedFade } from '@animations'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcColumn,
  AcOnboardingSuccessSVG,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'

export interface IOrderCompletedView {
  isEditOrder?: boolean
}

export const OrderCompletedView = observer(
  ({ isEditOrder = false }: IOrderCompletedView) => {
    const navigate = useNavigate()

    return (
      <AcAnimatedFade
        enter={{ x: 0, y: 0 }}
        exit={{ x: -20, y: 0 }}
        style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <AcColumn
          alignItems="center"
          textAlign="center">
          <AcOnboardingSuccessSVG
            color="green"
            checkColor="white"
            style={{
              scale: 0.5,
            }}
          />
          <AcColumn gap={3}>
            <AcTypography
              px={2}
              component="h2"
              color="green.main"
              padding="sm">
              {isEditOrder ? LABELS.ORDER_EDITED : LABELS.ORDER_COMPLETED}
            </AcTypography>
            <AcButton
              sx={{ marginTop: 'auto' }}
              fullWidth
              onClick={() => navigate(NAVIGATE_TO.ADMIN_DASHBOARD)}>
              {LABELS.BACK_TO_DASHBOARD}
            </AcButton>
          </AcColumn>
        </AcColumn>
      </AcAnimatedFade>
    )
  },
)

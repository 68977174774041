import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import {
  AcColumn,
  AcContainer,
  AcLoaderPulse,
  AcOrderCard,
  AcPreviousOrders,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks'

export const OrdersView = observer(() => {
  const { orders } = useStore()
  useEffect(() => {
    orders.fetchOrders()
  }, [])

  return (
    <AcContainer>
      <AcColumn mb={6}>
        <AcTypography
          component="h1"
          removePadding>
          {LABELS.ORDERS}
        </AcTypography>
        {orders.watchLoading ? (
          <AcColumn py={5}>
            <AcLoaderPulse />
          </AcColumn>
        ) : (
          <AcColumn>
            {orders.watchFutureOrders?.length
              ? orders.watchFutureOrders.map(order => (
                  <AcOrderCard
                    isNextWeekOrder
                    order={order}
                  />
                ))
              : null}
            {orders.watchCurrentOrder && (
              <AcOrderCard order={orders.watchCurrentOrder} />
            )}
          </AcColumn>
        )}
      </AcColumn>
      <AcTypography
        component="h2"
        color="green.main">
        {LABELS.PREVIOUS_ORDERS}
      </AcTypography>
      {orders.watchLoading ? (
        <AcColumn py={5}>
          <AcLoaderPulse />
        </AcColumn>
      ) : (
        <AcPreviousOrders />
      )}
    </AcContainer>
  )
})

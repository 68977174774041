import { useEffect, useMemo } from 'react'
import { FormControl, FormGroup, FormLabel } from '@mui/material'

import {
  AcCheckboxContained,
  AcCheckboxDefault,
  AcHelpertext,
} from '@components'

export interface ICheckboxGroupOption {
  title?: string
  label?: string
  value: string | number
  checked?: boolean
}

export interface ICheckboxGroup {
  id: string
  label?: string
  options: ICheckboxGroupOption[]
  variant?: 'default' | 'contained'
  fullWidth?: boolean
  value?: any[]
  onChange?: (val: any[]) => void
  useInt?: boolean
  disabled?: boolean
  error?: string
}

export const AcCheckboxGroup = ({
  id,
  label,
  options,
  variant = 'contained',
  value = [],
  onChange,
  fullWidth,
  useInt = false,
  disabled,
  error,
}: ICheckboxGroup) => {
  const handleChange = ({ target }: any) => {
    const checked = target.checked
    const newVal = useInt ? parseInt(target.value) : target.value

    const currentValue = !value ? [] : value

    if (onChange) {
      if (checked) {
        onChange([...currentValue, newVal])
      } else {
        onChange(currentValue.filter(item => item !== newVal))
      }
    }
  }

  const CheckboxEl =
    variant === 'contained' ? AcCheckboxContained : AcCheckboxDefault

  const renderOptions = useMemo(() => {
    return options.map((option, i) => {
      return (
        <CheckboxEl
          key={`${label}-${i}`}
          value={option.value}
          title={option.title}
          label={option.label}
          checked={value?.includes(option.value)}
        />
      )
    })
  }, [options, value, disabled])

  return (
    <FormControl
      fullWidth={fullWidth}
      disabled={disabled}>
      {label && (
        <FormLabel
          color="black"
          id={id}>
          {label}
        </FormLabel>
      )}
      <FormGroup
        onChange={handleChange}
        aria-labelledby={id}>
        {renderOptions}
      </FormGroup>
      {error && (
        <AcHelpertext
          text={error}
          isError
        />
      )}
    </FormControl>
  )
}

import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import {
  fetchOrderByYearParams,
  IMetaPaginationResponse,
  IOrderDetails,
  IOrderPreferencePayload,
  IOrderSummary,
} from '@typings'

export class SpecificationsApi extends AxiosBaseApi {
  index = () =>
    this.api
      .get<{
        data: { data: IOrderSummary[] }
      }>(ENDPOINTS.SPECIFICATIONS.INDEX)
      .then(response => response?.data?.data)

  index_year = (params: fetchOrderByYearParams) =>
    this.api
      .get<{
        data: { data: IOrderSummary[]; meta: IMetaPaginationResponse }
      }>(ENDPOINTS.SPECIFICATIONS.INDEX, { params })
      .then(response => response?.data)

  get_single = (id: number) =>
    this.api
      .get<{
        data: { data: IOrderDetails }
      }>(ENDPOINTS.SPECIFICATIONS.SINGLE(id))
      .then(response => response.data?.data)

  get_single_by_identifier = (identifier: string) =>
    this.api
      .get<{
        data: { data: IOrderDetails }
      }>(ENDPOINTS.SPECIFICATIONS.SINGLE_BY_IDENTIFIER(identifier))
      .then(response => response.data?.data)

  validate_order_luncher_count = (payload: any) =>
    this.precognition(['monday', 'tuesday', 'wednesday', 'thursday', 'friday'])
      .post<{}>(ENDPOINTS.SPECIFICATIONS.INDEX, payload)
      .then(response => response)

  validate_order_preferences = (payload: IOrderPreferencePayload) =>
    this.precognition([
      'salad',
      'soup',
      'comment',
      ...(payload?.soup?.map((_, i) => `soup.${i}`) || []),
      ...(payload?.salad?.map((_, i) => `salad.${i}`) || []),
    ])
      .post<{}>(ENDPOINTS.SPECIFICATIONS.INDEX, payload)
      .then(response => response)

  create_order = (body: any) =>
    this.api
      .post<{
        data: { data: IOrderDetails }
      }>(ENDPOINTS.SPECIFICATIONS.INDEX, body)
      .then(response => response?.data?.data)

  edit_validate_order_luncher_count = (id: IOrderDetails['id'], payload: any) =>
    this.precognition(['monday', 'tuesday', 'wednesday', 'thursday', 'friday'])
      .put<{}>(ENDPOINTS.SPECIFICATIONS.SINGLE(id), payload)
      .then(response => response)

  edit_validate_order_preferences = (
    id: IOrderDetails['id'],
    payload: IOrderPreferencePayload,
  ) =>
    this.precognition([
      'salad',
      'soup',
      'comment',
      ...(payload?.soup?.map((_, i) => `soup.${i}`) || []),
      ...(payload?.salad?.map((_, i) => `salad.${i}`) || []),
    ])
      .put<{}>(ENDPOINTS.SPECIFICATIONS.SINGLE(id), payload)
      .then(response => response)

  edit_order = (id: IOrderDetails['id'], body: any) =>
    this.api
      .put<{
        data: { data: IOrderDetails }
      }>(ENDPOINTS.SPECIFICATIONS.SINGLE(id), body)
      .then(response => response?.data?.data)
}

import type { AxiosError } from 'axios'
import { action, computed, makeAutoObservable, toJS } from 'mobx'

import { STORE_KEYS } from '@constants'
import { extractAPIFieldErrors } from '@helpers'
import { Store } from '@stores'
import { ILegendItem, IWeeksSingle } from '@typings'
import { getPercentage } from '@utilities/helpers/get-percentage.helpers'

export class WeeksStore {
  store: Store
  week: IWeeksSingle | null
  weekLunchers: any
  attendanceWeeks: any
  loading: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.week = null
    this.attendanceWeeks = null
    this.loading = false
    this.weekLunchers = null
  }

  @computed
  get current(): IWeeksSingle | null {
    return this.week
  }

  @computed
  get currentAttendanceWeeks() {
    return this.attendanceWeeks
  }

  @computed
  get legend(): ILegendItem[] {
    return this.week?.chart.legend?.map((item: any) => ({
      value: item.label !== 'bevestigd' ? item.value : `${item.value}%`,
      label: item.label,
    }))
  }

  @action
  setWeek = (week: IWeeksSingle) => {
    this.week = week
  }

  @action
  setWeekLunchers = (lunchers: IWeeksSingle) => {
    this.weekLunchers = lunchers
  }

  @action
  resetWeekLunchers = () => {
    this.weekLunchers = null
  }

  @action
  resetWeek = () => {
    this.week = null
  }

  @action
  getWeek = async (week: string) => {
    this.store.set(STORE_KEYS.WEEKS, 'loading', true)
    try {
      const { data } = await this.store.api.weeks.getWeek(week)
      this.setWeek(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.WEEKS, 'loading', false)
    }
  }

  @action
  getWeekLunchers = async (week: string) => {
    this.store.set(STORE_KEYS.WEEKS, 'loading', true)
    try {
      const { data } = await this.store.api.weeks.getWeekLunchers(week)
      this.setWeekLunchers(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.WEEKS, 'loading', false)
    }
  }

  @action
  getWeekAttendance = async () => {
    this.store.set(STORE_KEYS.WEEKS, 'loading', true)
    try {
      const { data } = await this.store.api.weeks.getAttendanceWeeks()
      this.store.set(STORE_KEYS.WEEKS, 'attendanceWeeks', data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.WEEKS, 'loading', false)
    }
  }

  @action
  cancel = async (weekIdentifier: string, reason: string) => {
    this.store.set(STORE_KEYS.WEEKS, 'loading', true)
    try {
      await this.store.api.weeks.cancel(weekIdentifier, reason)
      return Promise.resolve(true)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.WEEKS, 'loading', false)
    }
  }

  @action
  downloadList = async (weekIdentifier: string) => {
    try {
      const res = await this.store.api.weeks.downloadList(weekIdentifier)
      const a = document.createElement('a')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(res.data)
      a.href = url
      a.download = 'Luncher lijst'
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
      return Promise.resolve(true)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    }
  }

  @action
  reset = () => {
    this.week = null
    this.attendanceWeeks = null
    this.loading = false
    this.weekLunchers = null
  }
}

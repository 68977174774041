import WelcomeVisual from '@assets/visuals/onboarding_welcome.webp'
import {
  AcCard,
  AcColumn,
  AcFormInput,
  AcImage,
  AcNumberInput,
  AcTypography,
  IAcCard,
} from '@components'
import { formatPrice } from '@helpers'
import { useBreakpoints } from '@hooks'
import { IMedia } from '@typings'

interface IAcExtrasCard extends IAcCard {
  title: string
  image?: IMedia
  subtitle?: string
  price?: number
  withInput?: boolean
  registerKey?: string
}

export const AcExtrasCard = ({
  image,
  title,
  subtitle,
  price,
  withInput,
  registerKey,
  ...props
}: IAcExtrasCard) => {
  const { isMobile } = useBreakpoints()

  return (
    <AcCard
      padding="sm"
      sx={{
        border: 0,
        width: isMobile ? 239 : 264,
        display: 'flex',
      }}
      {...props}>
      <AcColumn
        gap={4}
        justifyContent="space-between"
        alignSelf="stretch"
        overflow="hidden">
        <AcImage
          {...image}
          src={image?.url || WelcomeVisual}
          sx={{
            width: '100%',
            height: 200,
            aspectRatio: '16/9',
            objectFit: 'cover',
            borderRadius: '4px',
          }}
        />
        <AcColumn
          paddingInline={2}
          paddingBlockEnd={2}>
          <AcColumn gap={2}>
            <AcTypography
              removePadding
              bold
              component="p"
              size={'mdlg'}>
              {title}
            </AcTypography>
            <AcTypography
              removePadding
              size="md"
              component="p">
              {subtitle}
            </AcTypography>
          </AcColumn>
          {price ? (
            <AcTypography
              removePadding
              variant="h2"
              component="p"
              size="xl">
              {formatPrice(price)}
            </AcTypography>
          ) : null}
        </AcColumn>
        {withInput && registerKey && (
          <AcFormInput
            register={registerKey}
            Component={
              <AcNumberInput
                fullWidth
                variant="styled"
                step={1}
                min={0}
              />
            }
          />
        )}
      </AcColumn>
    </AcCard>
  )
}

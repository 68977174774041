import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { AcOrdersTable, AcTabs } from '@components'
import { useStore } from '@hooks'
import { useDateHelpers } from '@hooks'

export const AcPreviousOrders = observer(() => {
  const { orders } = useStore()

  const { year } = useDateHelpers()
  const range = [year, year - 1, year - 2, year - 3].filter(
    year => year >= 2025,
  )

  const tabs = useMemo(() => {
    return range.map(year => ({
      title: year.toString(),
      content: <AcOrdersTable year={year} />,
    }))
  }, [orders.watchPreviousOrders])

  return (
    <AcTabs
      small
      tabs={tabs}
      gap={4}
    />
  )
})

import { ISinglePortalUser, IUser, IVerifyEmailEndpointArgs } from '@typings'

const USERS = '/users'
const USER = '/user'
const PROFILE = '/profile'
const AUTH = '/auth'
const WEEKS = '/weeks'
const PRODUCT = '/product'
const SPECIFICATIONS = '/specifications'
const LUNCHER = '/luncher'
const PREFERENCES = '/preferences'
const INVITE = '/invite'
const SOUPS_SALADS = '/soups-salads'
const DOWNLOAD = '/download'

export const ENDPOINTS = {
  USERS: {
    INDEX: USERS + '?per_page=200',
    SINGLE: (ID: IUser['id']) => `${USERS}/${ID}`,
    CREATE: USERS,
    INVITE: (QUERY: string) => `${USERS}${INVITE}${QUERY}`,
    DELETE: (ID: ISinglePortalUser['id']) => `${USERS}/${ID}`,
  },

  USER: {
    WHO_AM_I: PROFILE,
    CHANGE_PASSWORD: `${PROFILE}/change-password`,
    ROLES: `${USER}/roles`,
    ALLERGIES: `${USER}/allergies`,
    PREFERENCE: `${USER}/preference`,
    DEFAULT_ATTENDANCE: `${USER}/default-attendance`,
    PROFILE: PROFILE,
  },

  AUTHENTICATION: {
    LOGIN: `${AUTH}/login`,
    REGISTER: `${AUTH}/register`,
    INVITE: `${AUTH}/invite`,
    REQUEST_NEW_PASSWORD: `${AUTH}/forgot-password`,
    VALIDATE_RESET: `${AUTH}/validate-reset`,
    RESET_PASSWORD: `${AUTH}/reset-password`,
    VERIFY_EMAIL: ({
      userId,
      hash,
      signature,
      expires,
    }: IVerifyEmailEndpointArgs) =>
      `${AUTH}/verify/${userId}/${hash}?signature=${signature}&expires=${expires}`,
    RESEND_EMAIL_VERIFICATION: `${AUTH}/verify/resend`,
  },

  LUNCHER: {
    WEEKS: `${LUNCHER}${WEEKS}`,
  },

  WEEKS: {
    SINGLE: (week: string) => `${WEEKS}/${week}`,
    SINGLE_LUNCHERS: (week: string) => `${WEEKS}/${week}/lunchers`,
    DOWNLOAD: (week: string) => `${WEEKS}/${week}/download`,
  },

  PRODUCTS: {
    EXTRAS: (week: string) => `${PRODUCT}/extras?week=${week}`,
    ALLERGIES: `${PRODUCT}/allergies`,
    RATE: (id: number, score: number) => `/product/${id}/rating?score=${score}`,
    PREFERENCES: `${PRODUCT}${PREFERENCES}`,
    SOUPS_SALADS: (identifier: string) =>
      `${PRODUCT}${SOUPS_SALADS}?week=${identifier}`,
  },

  SPECIFICATIONS: {
    INDEX: SPECIFICATIONS,
    SINGLE: (id: number) => `${SPECIFICATIONS}/${id}`,
    CANCEL: (week: string, comment: string) =>
      `${SPECIFICATIONS}/cancel?week=${week}&comment=${comment}`,
    SINGLE_BY_IDENTIFIER: (identifier: string) =>
      `${SPECIFICATIONS}?weekIdentifier=${identifier}`,
  },
}
